import { schema } from 'normalizr';
import reviewSchema from './review';
import UnitSchema from './unit';

const facilitySchema = new schema.Entity('facilities', {
	units: {
		storageUnits: [UnitSchema],
		vehicleUnits: [UnitSchema],
		smallUnit: UnitSchema,
		mediumUnit: UnitSchema,
		largeUnit: UnitSchema,
		recommendedUnit: UnitSchema,
		enclosed: UnitSchema,
		outdoorCovered: UnitSchema,
		outdoorUncovered: UnitSchema,
		indoor: UnitSchema,
	},
	userReviews: [reviewSchema],
	bestReviews: [reviewSchema],
});

facilitySchema.define({
	nearbyFacilities: [facilitySchema],
});

export default facilitySchema;
