// ------------------------------------
// Global Toll Free Phone State
// ------------------------------------
import { pick } from 'lodash';
import API from '@sparefoot/react-core/symbols/api';

// ------------------------------------
// Constants
// ------------------------------------
export const GET_TOLLFREE_NUMBER_REQUEST =
	'SSDC/PHONE/GET_TOLLFREE_NUMBER_REQUEST';
export const GET_TOLLFREE_NUMBER_SUCCESS =
	'SSDC/PHONE/GET_TOLLFREE_NUMBER_SUCCESS';
export const GET_TOLLFREE_NUMBER_FAILURE =
	'SSDC/PHONE/GET_TOLLFREE_NUMBER_FAILURE';

export const GET_CITY_NUMBER_REQUEST = 'SSDC/PHONE/GET_CITY_NUMBER_REQUEST';
export const GET_CITY_NUMBER_SUCCESS = 'SSDC/PHONE/GET_CITY_NUMBER_SUCCESS';
export const GET_CITY_NUMBER_FAILURE = 'SSDC/PHONE/GET_CITY_NUMBER_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------

export const initialState = {
	stickyTrayPhoneNumber: null,
};

// Pick out the important elements from any phone object
export const pickPhone = (phone) => pick(phone, ['number', 'formattedNumber']);

const ERROR_NUMBER = {
	number: '8772088215',
	formattedNumber: '1 (877) 208-8215',
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_TOLLFREE_NUMBER_SUCCESS:
			return {
				...state,
				headerPhone: pickPhone(action.payload.phone),
			};
		case GET_TOLLFREE_NUMBER_FAILURE:
			return {
				...state,
				headerPhone: ERROR_NUMBER,
			};
		case GET_CITY_NUMBER_SUCCESS:
			return {
				...state,
				stickyTrayPhoneNumber: action.payload.phone,
			};
		case GET_CITY_NUMBER_FAILURE:
			return {
				...state,
				stickyTrayPhoneNumber: ERROR_NUMBER,
			};
		default:
			return state;
	}
}

// ------------------------------------
// Actions
// ------------------------------------
export function getTollfreePhoneNumber() {
	return {
		[API]: {
			types: [
				GET_TOLLFREE_NUMBER_REQUEST,
				GET_TOLLFREE_NUMBER_SUCCESS,
				GET_TOLLFREE_NUMBER_FAILURE,
			],
			endpoint: '/api/phone/tollfree',
		},
	};
}

export function getLocalCityPhoneNumber(city, state) {
	return {
		[API]: {
			types: [
				GET_CITY_NUMBER_REQUEST,
				GET_CITY_NUMBER_SUCCESS,
				GET_CITY_NUMBER_FAILURE,
			],
			endpoint: `/api/phone/local/${city}/${state}`,
		},
	};
}
