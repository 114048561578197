const paramsAreArrayKeys = ['amenities'];

export default (url) => {
	const urlParams = new URLSearchParams(url);
	const params = {};

	// Convert URLSearchParams to an array of entries and iterate
	Array.from(urlParams.entries()).forEach(([key, value]) => {
		// Currently only amenities operates as a [], extensible to other fields
		if (paramsAreArrayKeys.includes(key)) {
			// If the key is 'amenities', add the value to an array
			if (!params[key]) {
				params[key] = [];
			}

			params[key].push(value);
		} else {
			// For other keys, directly assign the value
			params[key] = value;
		}
	});

	return params;
};
