// ------------------------------------
// Constants
// ------------------------------------
export const READY = 'SSDC/APP/READY';
export const ROUTE_LOADED = 'SSDC/APP/ROUTE_LOADED';
export const ROUTE_UNLOADED = 'SSDC/APP/ROUTE_UNLOADED';

export const SERVER_RENDER_ERROR = 'SSDC/APP/SERVER_RENDER_ERROR';
export const COMPONENT_RENDER_ERROR = 'SSDC/APP/COMPONENT_RENDER_ERROR';

// ------------------------------------
// Initial State
// ------------------------------------

export const initialState = {
	id: null,
	ready: false,
	ssag_host: '',
	baseUrl: '',
	trackingURL: null,
	trackingId: null,
	segmentWriteKey: null,
	googleMapsKey: null,
	environment: 'development',
	statusCode: 200,
	error: null,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case READY:
			return {
				...state,
				ready: true,
			};
		case SERVER_RENDER_ERROR:
		case COMPONENT_RENDER_ERROR:
			return {
				...state,
				statusCode: 500,
				error: action.error,
			};
		default:
			return state;
	}
}

// ------------------------------------
// Actions
// ------------------------------------
export function appReady() {
	return {
		type: READY,
	};
}

export function routeLoaded(location) {
	return {
		type: ROUTE_LOADED,
		payload: { location },
	};
}

export function routeUnloaded(location) {
	return {
		type: ROUTE_UNLOADED,
		payload: { location },
	};
}

export function serverRenderError(error) {
	return {
		type: SERVER_RENDER_ERROR,
		error,
	};
}

export function componentRenderError(error, stackTrace) {
	return (dispatch, getState) => {
		const {
			app: { environment },
		} = getState();
		return dispatch({
			type: COMPONENT_RENDER_ERROR,
			error: environment !== 'development' ? error.message : stackTrace,
		});
	};
}
