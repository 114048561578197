/* eslint-disable react/destructuring-assignment */
import { Link } from '@sparefoot/react-core';
import { overwriteDisplayName } from '@sparefoot/react-core/segment';
import { matchPath } from 'react-router-dom';
import { omit } from 'lodash';
import classnames from 'classnames';
import {
	branch,
	compose,
	lifecycle,
	mapProps,
	withPropsOnChange,
	renderComponent,
} from 'recompose';
import { staticRoutes } from 'routes';

import './Link.scss';

export const enhance = compose(
	overwriteDisplayName('Link'),
	withPropsOnChange(
		['className', 'secondary'],
		({ className, secondary, tertiary, quaternary, underline }) => ({
			className: classnames(
				'ss-link',
				{ 'ss-link-secondary': secondary },
				{ 'ss-link-tertiary': tertiary },
				{ 'ss-link-quaternary': quaternary },
				{ 'ss-link-underline': underline },
				className,
			),
		}),
	),
	branch(({ preloadRoute }) => !preloadRoute, renderComponent(Link)),
	lifecycle({
		componentDidMount() {
			const { to } = this.props;
			const matchingRoute = staticRoutes.find((route) =>
				matchPath(to.split('?')[0], {
					path: route.path || '',
					exact: true,
					strict: true,
				}),
			);
			if (matchingRoute && matchingRoute.component.preload) {
				matchingRoute.component.preload();
			}
		},
	}),
	mapProps((props) => omit(props, ['preloadRoute'])),
);

export default enhance(Link);
