import PropTypes from 'prop-types';

export const phonePropType = PropTypes.shape({
	number: PropTypes.string.isRequired,
	formattedNumber: PropTypes.string.isRequired,
});

export const bookingPropType = PropTypes.shape({
	confirmationCode: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
});

export const unitPropType = PropTypes.shape({
	size: PropTypes.string,
	type: PropTypes.string,
	width: PropTypes.number,
	length: PropTypes.number,
	quantity: PropTypes.number,
	special: PropTypes.string,
	price: PropTypes.shape({
		meta: PropTypes.number,
	}),
	availableAmenities: PropTypes.arrayOf(PropTypes.string),
	vehicleAccommodation: PropTypes.string,
	isOutOfBookingRange: PropTypes.bool,
});

export const metaPropTypes = {
	noIndex: PropTypes.bool,
	noFollow: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	canonical: PropTypes.string,
	meta: PropTypes.object,
	links: PropTypes.arrayOf(PropTypes.object),
};

export const statePageContentPropTypes = {
	state: PropTypes.string.isRequired,
	stateNickname: PropTypes.string.isRequired,
	welcome: PropTypes.string.isRequired,
	selfStorageFacts: PropTypes.shape({
		copy: PropTypes.string,
		facts: PropTypes.arrayOf(
			PropTypes.shape({
				fact: PropTypes.string.isRequired,
				highlight: PropTypes.string.isRequired,
			}),
		),
	}).isRequired,
	cityPriceList: PropTypes.arrayOf(
		PropTypes.shape({
			city: PropTypes.string,
			price: PropTypes.string,
		}),
	).isRequired,
	reasonsToMove: PropTypes.shape({
		copy: PropTypes.string,
		reasons: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	movingTo: PropTypes.shape({
		copy: PropTypes.string,
	}).isRequired,
	economicOutlook: PropTypes.shape({
		copy: PropTypes.string.isRequired,
		breakdown: PropTypes.arrayOf(
			PropTypes.shape({
				sector: PropTypes.string,
				value: PropTypes.string,
			}),
		).isRequired,
		bottomLine: PropTypes.string,
	}).isRequired,
	largestEmployers: PropTypes.arrayOf(
		PropTypes.shape({
			employer: PropTypes.string,
			employees: PropTypes.string,
		}),
	).isRequired,
	placesToLive: PropTypes.shape({
		copy: PropTypes.string,
		cities: PropTypes.arrayOf(
			PropTypes.shape({
				city: PropTypes.string,
				copy: PropTypes.string,
				facts: PropTypes.arrayOf(
					PropTypes.shape({
						item: PropTypes.string.isRequired,
						value: PropTypes.string.isRequired,
					}).isRequired,
				).isRequired,
			}).isRequired,
		).isRequired,
	}).isRequired,
	movingToResources: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			copy: PropTypes.string,
			link: PropTypes.string,
		}).isRequired,
	).isRequired,
	sources: PropTypes.arrayOf(PropTypes.string),
};
