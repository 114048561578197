import React from 'react';
import { hydrate } from 'react-dom';
import createStore from 'store/create';
import initialize from 'utils/app/initialize';
import App from 'containers/App';

const store = createStore(window.__PRELOADED_STATE__);
initialize(store);

const render = (AppComponent) =>
	hydrate(
		<AppComponent store={store} />,
		document.getElementById('js-page-content'),
	);

render(App);

if (module.hot) {
	module.hot.accept(['./containers/App', './pages/Bundles'], () => {
		const NextApp =
			require('./containers/App').default; /* eslint-disable-line global-require, max-len */
		render(NextApp);
	});
}
