import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Row.scss';

export default function Row({ align, children, className, reverse }) {
	return (
		<div
			className={classnames('page-row', className, {
				reverse,
				[`align-${align}`]: align,
			})}
		>
			{children}
		</div>
	);
}

Row.propTypes = {
	align: PropTypes.oneOf(['center', 'start', 'end']),
	children: PropTypes.node,
	className: PropTypes.string,
	reverse: PropTypes.bool,
};
