import { schema } from 'normalizr';
import facilitySchema from './facility';

const searchSchema = new schema.Entity(
	'search',
	{
		listings: [facilitySchema],
	},
	{
		idAttribute: 'searchId',
	},
);

export default searchSchema;
