import { createSelector } from 'reselect';
import { get, includes } from 'lodash';
import { parseQueryParams } from '@sparefoot/react-core/utils/url';

export const getRecaptchaSiteKey = (state) => state.app.recaptchaSiteKey;

export const getQueryParams = (state, props) =>
	parseQueryParams(get(props, 'location.search'));

export const getCanonical =
	(path) =>
	({ app }) =>
		`${app.baseUrl}${path}`;

export default createSelector(
	(state) => state.app.statusCode,
	(state) => state.app.environment,
	(state) => state.app.ready,
	(state) => state.app.error,
	(state) => state.phone,
	(state, props) => props.location,
	(state, props) => props.cookies,
	(statusCode, environment, ready, error, phone, location, cookies) => {
		const queryParams = parseQueryParams(location.search);
		return {
			ready,
			phone: phone.headerPhone,
			sftest:
				includes(['on', 'active'], queryParams.sftest) ||
				cookies.get('sftest') === 'on',
			statusCode,
			errorMessage: environment !== 'production' && error,
		};
	},
);
