import { get } from 'lodash';

export default class RequestError extends Error {
	constructor(error) {
		super(error);
		const message =
			get(error, 'response.data.message') ||
			get(error, 'response.statusText', '');
		this.response = get(error, 'response.data', {});
		this.error = error;
		this.status = get(error, 'response.status', null);
		this.errorMessage = message;
	}
}
