import React from 'react';

export default function Dropdown() {
	return (
		<svg
			className="svg dropdown"
			xmlns="http://www.w3.org/2000/svg"
			width="13"
			height="13"
			viewBox="0 0 1792 1792"
		>
			<path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19L403 749q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z" />
		</svg>
	);
}
