import { get } from 'lodash';
import API from '@sparefoot/react-core/symbols/api';
import mergeEntities from 'utils/store/mergeEntities';
import FacilitySchema from '../schema/facility';
import UnitSchema from '../schema/unit';
import { FACILITY_SUCCESS } from './facilities';
import {
	SEARCH_SUCCESS,
	SEARCH_PAGE_SUCCESS,
	SEARCH_LANDING_PAGE_SUCCESS,
} from './search';

// ------------------------------------
// Constants
// ------------------------------------
export const UNIT_REQUEST = 'SSDC/UNITS/UNIT_REQUEST';
export const UNIT_SUCCESS = 'SSDC/UNITS/UNIT_SUCCESS';
export const UNIT_FAILURE = 'SSDC/UNITS/UNIT_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
	loading: false,
	error: null,
	statusCode: null,
	byId: {},
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case UNIT_REQUEST:
			return {
				...state,
				loading: true,
				statusCode: null,
			};
		case FACILITY_SUCCESS:
		case UNIT_SUCCESS:
		case SEARCH_SUCCESS:
		case SEARCH_PAGE_SUCCESS:
		case SEARCH_LANDING_PAGE_SUCCESS: {
			const units = get(action, 'payload.entities.units');
			if (units) {
				return {
					...state,
					loading: false,
					byId: mergeEntities(state.byId, units),
				};
			}
			return {
				...state,
				loading: false,
			};
		}
		case UNIT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				statusCode: 404,
			};
		default:
			return state;
	}
}

// ------------------------------------
// Actions
// ------------------------------------
export function getUnit(facilityId, unitId) {
	return {
		[API]: {
			types: [UNIT_REQUEST, UNIT_SUCCESS, UNIT_FAILURE],
			endpoint: `/api/facility/${facilityId}/unit/${unitId}`,
			schema: { facility: FacilitySchema, unit: UnitSchema },
		},
	};
}
