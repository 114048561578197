import {
	overwriteDisplayName,
	withSegmentContext,
} from '@sparefoot/react-core/segment';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';

import './Section.scss';

const enhance = compose(overwriteDisplayName('Section'), withSegmentContext);

export function Section({ children, className, id }) {
	return (
		<div
			id={id}
			className={classnames('page-section', className)}
		>
			{children}
		</div>
	);
}

Section.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	id: PropTypes.string,
};

export default enhance(Section);
