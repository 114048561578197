import React from 'react';

export default function Logo() {
	return (
		<svg
			className="svg logo"
			xmlns="http://www.w3.org/2000/svg"
			width="117"
			height="28"
			viewBox="0 0 250 21"
			fill="none"
		>
			<g fill="#234C69">
				<path d="M.272 19.373 1.478 17.7c1.012 1.168 2.918 2.14 4.98 2.14 2.295 0 3.579-1.09 3.579-2.606 0-3.657-9.337-1.4-9.337-7.003 0-2.373 1.984-4.357 5.602-4.357 2.607 0 4.396.973 5.602 2.179l-1.089 1.633C9.92 8.597 8.286 7.78 6.302 7.78c-2.061 0-3.345 1.05-3.345 2.373 0 3.268 9.336 1.128 9.336 7.003 0 2.528-2.023 4.551-5.913 4.551-2.49.04-4.551-.739-6.108-2.334Z" />
				<path
					fillRule="evenodd"
					d="M22.68 5.913c-4.318 0-7.43 3.501-7.43 7.897 0 4.707 3.229 7.936 7.742 7.897 2.412 0 4.473-.817 5.99-2.334l-1.128-1.517a6.687 6.687 0 0 1-4.707 1.945c-3.268 0-5.213-2.373-5.407-5.213h12.215v-.583c0-4.552-2.723-8.131-7.275-8.092Zm-4.98 6.925c.118-2.296 1.713-5.019 4.942-5.019 3.462 0 4.94 2.801 4.979 5.019h-9.92Z"
					clipRule="evenodd"
				/>
				<path d="M33.728 21.357V.505h2.335v20.852h-2.335ZM41.431 21.357V8.325h-2.528V6.263h2.49V5.096c0-3.112 1.711-4.902 4.396-4.902 1.206 0 2.256.273 3.19 1.167l-.973 1.518c-.506-.428-1.09-.7-1.828-.7-1.556 0-2.412 1.05-2.412 2.956v1.167h3.073v2.062h-3.073v13.032h-2.334v-.039ZM47.773 19.412l1.983-3.423c1.129 1.011 3.54 2.023 5.33 2.023 1.44 0 2.023-.39 2.023-1.05 0-1.868-8.792.077-8.792-6.07 0-2.645 2.295-4.979 6.458-4.979 2.568 0 4.707.817 6.302 1.984L59.25 11.32c-.895-.894-2.646-1.672-4.435-1.672-1.128 0-1.867.428-1.867 1.05 0 1.673 8.753-.078 8.753 6.147 0 2.879-2.53 4.94-6.847 4.94-2.724-.039-5.486-.933-7.08-2.373ZM65.2 17.312v-6.886h-2.49V6.263h2.49V2.14h4.825v4.123h3.073v4.163h-3.073v5.524c0 .895.505 1.517 1.322 1.517.545 0 1.05-.194 1.206-.389l.934 3.618c-.584.545-1.79 1.012-3.58 1.012-3.072.039-4.706-1.479-4.706-4.396Z" />
				<path
					fillRule="evenodd"
					d="M82.668 5.913c-5.135 0-8.209 3.657-8.209 7.897s3.074 7.936 8.209 7.936c5.174 0 8.247-3.695 8.247-7.936 0-4.24-3.073-7.897-8.247-7.897Zm0 4.24c2.1 0 3.307 1.673 3.307 3.657 0 2.023-1.167 3.696-3.307 3.696-2.062 0-3.268-1.673-3.268-3.696 0-1.984 1.206-3.657 3.268-3.657Z"
					clipRule="evenodd"
				/>
				<path d="M93.444 21.357V6.263h4.824V8.09c1.011-1.206 2.956-2.256 4.707-2.256v4.63c-.272-.078-.7-.117-1.206-.117-1.245 0-2.918.544-3.54 1.361v9.648h-4.785Z" />
				<path
					fillRule="evenodd"
					d="M114.101 19.84v1.517h4.785v-9.453c0-4.668-3.423-6.03-7.041-6.03-2.296 0-4.902.661-6.847 2.334l1.789 3.346c1.245-1.05 2.723-1.634 4.241-1.634 1.945 0 3.073.895 3.073 2.217v1.518c-.934-1.129-2.723-1.79-4.707-1.79-2.295 0-5.174 1.206-5.174 4.863 0 3.384 2.84 4.98 5.174 4.98 1.945 0 3.773-.74 4.707-1.868Zm0-3.696v1.362c-.506.7-1.634 1.09-2.684 1.09-1.245 0-2.412-.546-2.412-1.752 0-1.205 1.167-1.75 2.412-1.75 1.05 0 2.178.35 2.684 1.05ZM124.138 21.863l-2.101 3.384c1.907 1.596 4.085 2.257 6.692 2.218 3.773 0 8.675-1.284 8.675-7.43V6.225h-4.824v1.789c-1.206-1.479-2.801-2.14-4.474-2.14-3.773 0-6.613 2.606-6.613 7.586 0 5.096 2.917 7.625 6.613 7.625 1.712 0 3.229-.7 4.474-2.218v1.168c0 2.878-2.256 3.579-3.851 3.579-1.751 0-3.385-.506-4.591-1.751Zm8.403-10.387v4.046c-.584.817-1.867 1.322-2.879 1.322-1.906 0-3.307-1.167-3.307-3.384 0-2.179 1.401-3.346 3.307-3.346 1.012 0 2.295.545 2.879 1.362ZM147.907 5.913c-4.746 0-8.013 3.501-8.013 7.897 0 4.902 3.579 7.936 8.247 7.936 2.295 0 4.785-.622 6.302-1.984l-2.101-3.15c-.894.816-2.645 1.283-3.695 1.283-2.179 0-3.424-1.128-3.774-2.49h10.776v-1.089c0-5.135-3.268-8.403-7.742-8.403Zm-3.112 6.186c.195-1.09 1.012-2.335 3.112-2.335 2.218 0 2.996 1.284 3.113 2.335h-6.225Z"
					clipRule="evenodd"
				/>
				<path d="M158.139 19.957c0-.934.778-1.712 1.711-1.712.934 0 1.712.778 1.712 1.712 0 .933-.778 1.712-1.712 1.712a1.727 1.727 0 0 1-1.711-1.712ZM164.752 13.81c0-4.473 3.034-7.897 7.547-7.897 2.762 0 4.396 1.128 5.524 2.645l-1.556 1.401c-1.011-1.362-2.295-1.945-3.851-1.945-3.19 0-5.213 2.49-5.213 5.835 0 3.346 2.023 5.836 5.213 5.836 1.556 0 2.84-.623 3.851-1.984l1.556 1.439c-1.167 1.517-2.801 2.645-5.524 2.645-4.513-.039-7.547-3.5-7.547-7.975Z" />
				<path
					fillRule="evenodd"
					d="M187.744 5.913c-4.591 0-7.47 3.501-7.47 7.897 0 4.357 2.879 7.936 7.47 7.936 4.59 0 7.469-3.579 7.469-7.936 0-4.357-2.879-7.897-7.469-7.897Zm-.039 2.062c3.229 0 5.018 2.762 5.018 5.835 0 3.074-1.789 5.836-5.018 5.836s-5.019-2.724-5.019-5.836c0-3.073 1.79-5.835 5.019-5.835Z"
					clipRule="evenodd"
				/>
				<path d="M217.271 21.357V11.126c0-1.906-.817-3.112-2.801-3.112-1.556 0-3.229 1.089-4.007 2.295v11.087h-2.334v-10.27c0-1.906-.778-3.112-2.801-3.112-1.518 0-3.152 1.128-3.968 2.334v11.048h-2.335V6.263h2.335v2.179c.661-.973 2.645-2.568 4.901-2.568 2.296 0 3.579 1.245 4.046 2.801.856-1.362 2.879-2.801 5.057-2.801 2.763 0 4.241 1.517 4.241 4.552v10.931h-2.334Z" />
			</g>
		</svg>
	);
}
