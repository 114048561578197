import API from '@sparefoot/react-core/symbols/api';

// ------------------------------------
// Constants
// ------------------------------------
export const UNSUBSCRIBE_REQUEST = 'SSDC/EMAILS/UNSUBSCRIBE_REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'SSDC/EMAILS/UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'SSDC/EMAILS/UNSUBSCRIBE_FAILURE';

export const HOMEPAGE_REQUEST = 'SSDC/PAGES/HOMEPAGE_REQUEST';
export const HOMEPAGE_SUCCESS = 'SSDC/PAGES/HOMEPAGE_SUCCESS';
export const HOMEPAGE_FAILURE = 'SSDC/PAGES/HOMEPAGE_FAILURE';

export const DEFAULT_PAGE_REQUEST = 'SSDC/PAGES/DEFAULT_PAGE_REQUEST';
export const DEFAULT_PAGE_SUCCESS = 'SSDC/PAGES/DEFAULT_PAGE_SUCCESS';
export const DEFAULT_PAGE_FAILURE = 'SSDC/PAGES/DEFAULT_PAGE_FAILURE';

export const STATE_LANDING_PAGE_REQUEST =
	'SSDC/PAGES/STATE_LANDING_PAGE_REQUEST';
export const STATE_LANDING_PAGE_SUCCESS =
	'SSDC/PAGES/STATE_LANDING_PAGE_SUCCESS';
export const STATE_LANDING_PAGE_FAILURE =
	'SSDC/PAGES/STATE_LANDING_PAGE_FAILURE';

export const SUBMIT_CONTACT_FORM_REQUEST =
	'SSDC/PAGES/SUBMIT_CONTACT_FORM_REQUEST';
export const SUBMIT_CONTACT_FORM_SUCCESS =
	'SSDC/PAGES/SUBMIT_CONTACT_FORM_SUCCESS';
export const SUBMIT_CONTACT_FORM_FAILURE =
	'SSDC/PAGES/SUBMIT_CONTACT_FORM_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
	home: {},
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case HOMEPAGE_SUCCESS:
			return {
				...state,
				home: {
					meta: action.payload.meta,
					featuredCities: action.payload.featuredCities,
				},
			};
		case STATE_LANDING_PAGE_SUCCESS:
			return {
				...state,
				stateContent: {
					featuredCities: action.payload.featuredCities,
					allCities: action.payload.allCities,
					title: action.payload.title,
					body: action.payload.body,
					location: action.payload.location,
					contentId: action.payload.contentId,
					stateUrl: action.payload.stateUrl,
					meta: action.payload.meta,
				},
			};
		case STATE_LANDING_PAGE_FAILURE:
			return {
				...state,
				stateContent: {
					statusCode: 404,
				},
			};
		default:
			return state;
	}
}

// ------------------------------------
// Actions
// ------------------------------------
export function unsubscribeEmail(data) {
	return {
		[API]: {
			types: [
				UNSUBSCRIBE_REQUEST,
				UNSUBSCRIBE_SUCCESS,
				UNSUBSCRIBE_FAILURE,
			],
			method: 'POST',
			endpoint: '/api/unsubscribe',
			data,
		},
	};
}

export function getHomepage() {
	return {
		[API]: {
			types: [HOMEPAGE_REQUEST, HOMEPAGE_SUCCESS, HOMEPAGE_FAILURE],
			endpoint: '/api/page/index',
		},
	};
}

export function getDefaultPage() {
	return {
		[API]: {
			types: [
				DEFAULT_PAGE_REQUEST,
				DEFAULT_PAGE_SUCCESS,
				DEFAULT_PAGE_FAILURE,
			],
			endpoint: '/api/page/default',
		},
	};
}

export function getStateLandingPage(state) {
	return {
		[API]: {
			types: [
				STATE_LANDING_PAGE_REQUEST,
				STATE_LANDING_PAGE_SUCCESS,
				STATE_LANDING_PAGE_FAILURE,
			],
			endpoint: `/api/content/${state}`,
		},
	};
}

export function submitContactForm(data) {
	return {
		[API]: {
			types: [
				SUBMIT_CONTACT_FORM_REQUEST,
				SUBMIT_CONTACT_FORM_SUCCESS,
				SUBMIT_CONTACT_FORM_FAILURE,
			],
			method: 'POST',
			endpoint: '/api/contact',
			data,
		},
	};
}
