import API from '@sparefoot/react-core/symbols/api';

// ------------------------------------
// Constants
// ------------------------------------
export const SAVE_GA_REQUEST = 'SFDC/USER/SAVE_GA_REQUEST';
export const SAVE_GA_SUCCESS = 'SFDC/USER/SAVE_GA_SUCCESS';
export const SAVE_GA_FAILURE = 'SFDC/USER/SAVE_GA_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------

export const initialState = {
	siteId: 27,
	visitId: null,
	user_ip: null,
	traffic_source_id: null,
	cid: null,
	ctid: null,
	gcid: null,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SAVE_GA_SUCCESS:
			return {
				...state,
				gcid: action.meta.gcid,
			};
		default:
			return state;
	}
}

export function saveGAClientId(clientId) {
	return {
		[API]: {
			method: 'POST',
			types: [SAVE_GA_REQUEST, SAVE_GA_SUCCESS, SAVE_GA_FAILURE],
			endpoint: '/api/visit/meta',
			meta: {
				gcid: clientId,
			},
			data: {
				meta: {
					value: clientId,
					key: 'google_client_id',
				},
			},
		},
	};
}
