import { isUndefined, each, first, reduce, isFunction } from 'lodash';
import GoogleMapsLoader from '@sparefoot/google-maps';

let googleLoadPromise;
const elements = [];

export function initializeGoogle({ key }) {
	// allow ?MAPS_KEY=foobar to simulate invalid key error
	// eslint-disable-next-line no-unused-vars
	const [text, match, ...rest] =
		window.location.search.match('[?&]MAPS_KEY=(([^&#]*)|&|#|$)') || [];
	if (match) {
		// eslint-disable-next-line no-param-reassign
		key = match;
	}

	GoogleMapsLoader.KEY = key;
	GoogleMapsLoader.LIBRARIES = ['places'];
}

export function loadGoogle() {
	if (typeof window !== 'undefined') {
		googleLoadPromise = new Promise((resolve) => {
			GoogleMapsLoader.load((google) => resolve(google));
		});
	}
}

function createCircle(google, map, radius, color) {
	const circleOptions = {
		center: map.getCenter(),
		fillColor: color || '#FF0000',
		fillOpacity: 0,
		strokeOpacity: 0,
		map,
		radius: radius * 1609.34, // Convert miles to meters
	};

	return new google.maps.Circle(circleOptions);
}

export function getDistance(lat1, lon1, lat2, lon2) {
	// Convert degrees to radian
	const latRad1 = (lat1 * Math.PI) / 180;
	const lonRad1 = (lon1 * Math.PI) / 180;
	const latRad2 = (lat2 * Math.PI) / 180;
	const lonRad2 = (lon2 * Math.PI) / 180;

	const R = 3959;
	// Radius of Earth (in miles)

	const d =
		Math.acos(
			Math.sin(latRad1) * Math.sin(latRad2) +
				Math.cos(latRad1) *
					Math.cos(latRad2) *
					Math.cos(lonRad2 - lonRad1),
		) * R;

	return Math.abs(d);
}

export function unloadGoogle() {
	googleLoadPromise = undefined;
}

export function createStreetView(element, config) {
	if (!googleLoadPromise) {
		loadGoogle();
	}

	return googleLoadPromise.then((google) => {
		const coordinates = new google.maps.LatLng(
			config.latitude,
			config.longitude,
		);
		const streetViewClient = new google.maps.StreetViewService();

		return new Promise((resolve, reject) => {
			streetViewClient.getPanoramaByLocation(
				coordinates,
				50,
				(data, status) => {
					if (status !== google.maps.StreetViewStatus.OK) {
						reject(status);
					} else {
						const panoramaOptions = {
							position: coordinates,
						};

						if (config.yaw) {
							panoramaOptions.pov = {
								heading: config.yaw,
								pitch: config.pitch,
								zoom: config.xoom,
							};
						}

						const panorama = new google.maps.StreetViewPanorama(
							element,
							panoramaOptions,
						);
						resolve(panorama);
					}
				},
			);
		});
	});
}

export function createGoogleMap(element, config) {
	if (!googleLoadPromise) {
		loadGoogle();
	}

	return googleLoadPromise.then((google) => {
		const { latitude, longitude } = config.center;
		const center = new google.maps.LatLng(latitude, longitude);
		const options = {
			center,
			zoom: 15,
			clickableIcons: false,
			scrollWheel: false,
			mapTypeControl: false,
			streetViewControl: false,
			navigationControl: {
				style: google.maps.NavigationControlStyle.DEFAULT,
			},
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			zoomControlOptions: {
				position: google.maps.ControlPosition.TOP_LEFT,
			},
			fullscreenControl: false,
			gestureHandling: 'greedy',
			minZoom: '6',
		};

		const map = new google.maps.Map(element, options);

		if (config.radius) {
			const circle = createCircle(google, map, config.radius);
			map.fitBounds(circle.getBounds());
			circle.setMap(null);
		}

		if (isFunction(config.onDragEnd)) {
			google.maps.event.addListener(map, 'dragend', config.onDragEnd);
		}

		return { map, google };
	});
}

export function formatAddress(address) {
	// Google likes to put the country on the end of the place.
	// Since we only serve the United States, we can remove this.
	if (address.substr(-5) === ', USA') {
		return address.substr(0, address.length - 5);
	}
	if (address.substr(-15) === ', United States') {
		return address.substr(0, address.length - 15);
	}
	return address;
}

export function geocode(latitude, longitude) {
	// Load Google if not already loaded
	if (!googleLoadPromise) {
		loadGoogle();
	}

	return googleLoadPromise.then((google) => {
		const geocoder = new google.maps.Geocoder();
		return new Promise((resolve) => {
			geocoder.geocode(
				{
					location: { lat: latitude, lng: longitude },
				},
				resolve,
			);
		});
	});
}

export function removeAutocomplete(autoComplete) {
	return googleLoadPromise && autoComplete
		? googleLoadPromise.then((google) => {
				google.maps.event.clearInstanceListeners(autoComplete);
			})
		: Promise.resolve();
}

export function clearAutocomplete(autoComplete) {
	removeAutocomplete(autoComplete);
	autoComplete.removeAttribute('placeholder'); //	oops something went wrong
	autoComplete.removeAttribute('disabled'); //	user can type
	autoComplete.removeAttribute('style'); //	little warning icon
}

export function createAutocomplete(element, onPlaceChange) {
	// Load Google if not already loaded
	if (!googleLoadPromise) {
		loadGoogle();
	}

	elements.push(element);
	window.gm_authFailure = () => {
		each(elements, (theElement) => {
			clearAutocomplete(theElement);
		});
	};

	return googleLoadPromise.then((theGoogs) => {
		const autocomplete = new theGoogs.maps.places.Autocomplete(element, {
			types: ['geocode'],
			componentRestrictions: {
				country: 'us',
			},
		});

		theGoogs.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
			// place_changed is triggered when user presses enter (even though autocomplete wasn't used)
			// To prevent this from creating a change event, we can check for a place_id which won't exist
			// if enter was pressed.
			if (!isUndefined(place.place_id)) {
				onPlaceChange(place.formatted_address, place);
			}
		});

		return autocomplete;
	});
}

export function formatPlaceObject(place) {
	const params = {};

	if (place) {
		const addressComponents = place.address_components.reduce(
			(components, c) => ({
				...components,
				[first(c.types)]: c.short_name,
			}),
			{},
		);

		params.city = addressComponents.locality;
		params.zip = addressComponents.postal_code;
		params.state = addressComponents.administrative_area_level_1;
		params.locationSource = 'google_autocomplete';

		if (place.geometry) {
			params.latitude = place.geometry.location.lat();
			params.longitude = place.geometry.location.lng();
		}
	}
	return params;
}

// Translate all of the facility locations to a Google Maps LatLngBounds object.
export const locationsToBounds = (google, locations) =>
	reduce(
		locations,
		(bound, location) => {
			const facPoint = new google.maps.LatLng(
				location.latitude,
				location.longitude,
			);
			bound.extend(facPoint);
			return bound;
		},
		new google.maps.LatLngBounds(),
	);
