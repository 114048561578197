import { Button, Link } from '@sparefoot/react-core';
import { branch, withProps, compose, renderComponent } from 'recompose';
import { overwriteDisplayName } from '@sparefoot/react-core/segment';
import classnames from 'classnames';

import './Button.scss';
import '../Link/Link.scss';

export const enhance = compose(
	overwriteDisplayName('Button'),
	withProps(
		({
			className,
			fullWidth,
			primary,
			secondary,
			tertiary,
			link,
			underline,
		}) => ({
			isButton: true,
			className: classnames(
				className,
				link
					? {
							'ss-link': link,
							'ss-link-secondary': secondary,
							'ss-link-underline': underline,
						}
					: {
							'ss-button': primary || secondary || tertiary,
							'ss-button-primary': primary,
							'ss-button-secondary': secondary && !primary,
							'ss-button-full': fullWidth,
							'ss-button-tertiary':
								tertiary && !primary && !secondary,
						},
			),
		}),
	),
	branch((props) => props.href || props.to, renderComponent(Link)),
);

export default enhance(Button);
