import PropTypes from 'prop-types';
import {
	compose,
	defaultProps,
	mapProps,
	componentFromProp,
	setDisplayName,
	setPropTypes,
} from 'recompose';
import classnames from 'classnames';

import './Typography.scss';

export const withTypography = (element) =>
	compose(
		setDisplayName(element.toUpperCase()),
		setPropTypes({
			size: PropTypes.oneOf([
				'tiny',
				'small',
				'smallMedium',
				'standardMedium',
				'medium',
				'mediumLarge',
				'large',
				'megaMobile',
				'mega',
			]),
			color: PropTypes.oneOf([
				'black',
				'red',
				'white',
				'green',
				'greenHero',
				'blue',
				'priceBlue',
				'lightGray',
				'gray',
				'darkGray',
				'secondaryBlue',
				'placeholderGray',
			]),
			weight: PropTypes.oneOf([
				'extraLight',
				'light',
				'standard',
				'medium',
				'bold',
				'extraBold',
			]),
		}),
		defaultProps({
			typographyComponent: element,
		}),
		mapProps((props) =>
			props.eyebrow
				? { ...props, color: props.color || 'gray', size: 'tiny' }
				: props,
		),
		mapProps(
			({
				children,
				className,
				eyebrow,
				size,
				weight,
				color,
				typographyComponent,
				last,
				strikethrough,
				itemProp,
				itemScope,
				itemType,
			}) => ({
				children,
				typographyComponent,
				itemProp,
				itemScope,
				itemType,
				className: classnames(
					className,
					'ss-type',
					!!strikethrough && 'ss-type-strike',
					!!last && 'ss-type-last',
					!!size && `ss-type-${size}`,
					!!color && `ss-type-${color}`,
					!!weight && `ss-type-weight-${weight}`,
					!!eyebrow && 'ss-type-eyebrow',
				),
			}),
		),
	);

const componentFromTypographyProp = componentFromProp('typographyComponent');

export const H1 = withTypography('h1')(componentFromTypographyProp);
export const H2 = withTypography('h2')(componentFromTypographyProp);
export const H3 = withTypography('h3')(componentFromTypographyProp);
export const H4 = withTypography('h4')(componentFromTypographyProp);
export const H5 = withTypography('h5')(componentFromTypographyProp);
export const H6 = withTypography('h6')(componentFromTypographyProp);
export const Paragraph = withTypography('p')(componentFromTypographyProp);
export const Text = withTypography('span')(componentFromTypographyProp);
export const OrderedList = withTypography('ol')(componentFromTypographyProp);
export const UnorderedList = withTypography('ul')(componentFromTypographyProp);
export const ListItem = withTypography('li')(componentFromTypographyProp);
