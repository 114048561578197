/* eslint-disable max-len */
import universal from 'react-universal-component';
import { LoadingPage } from 'components/core/LoadingPage';

const options = {
	loading: LoadingPage,
	timeout: 60000,
};

// Component Bundles
export { ExperimentModal } from 'components/experiment/ExperimentModal';

// Page Bundles
export const HomePage = universal(
	import(/* webpackChunkName: "HomePage" */ './HomePage/HomePage'),
	options,
);
export const ContactPageV2 = universal(
	import(
		/* webpackChunkName: "ContactPageV2" */ './ContactPageV2/ContactPageV2'
	),
	options,
);
export const ErrorPage = universal(
	import(/* webpackChunkName: "ErrorPage" */ './ErrorPage/ErrorPage'),
	options,
);
export const SearchPage = universal(
	import(/* webpackChunkName: "SearchPage" */ './SearchPage/SearchPage'),
	options,
);
export const FacilityPage = universal(
	import(
		/* webpackChunkName: "FacilityPage" */ './FacilityPage/FacilityPage'
	),
	options,
);
export const UnitPage = universal(
	import(/* webpackChunkName: "UnitPage" */ './UnitPage/UnitPage'),
	options,
);
export const ConfirmationPage = universal(
	import(
		/* webpackChunkName: "ConfirmationPage" */ './ConfirmationPage/ConfirmationPage'
	),
	options,
);
export const LegalPage = universal(
	import(/* webpackChunkName: "LegalPage" */ './LegalPage/LegalPage'),
	options,
);
export const LegalReviewPage = universal(
	import(
		/* webpackChunkName: "LegalReviewPage" */ './LegalPage/LegalReviewPage'
	),
	options,
);
export const StatePage = universal(
	import(/* webpackChunkName: "StatePage" */ './StatePage/StatePage'),
	options,
);
export const MarketingLandingPage = universal(
	import(
		/* webpackChunkName: "MarketingLandingPage" */ './MarketingLandingPage/MarketingLandingPage'
	),
	options,
);
export const MoveInConfirmationPage = universal(
	import(
		/* webpackChunkName: "MoveInConfirmationPage" */ './MoveInConfirmationPage/MoveInConfirmationPage'
	),
	options,
);
export const StorageBasicsPage = universal(
	import(
		/* webpackChunkName: "StorageBasicsPage" */ './StorageBasicsPage/StorageBasicsPage'
	),
	options,
);
export const StyleGuide = universal(
	import(/* webpackChunkName: "StyleGuide" */ './StyleGuide/StyleGuide'),
	options,
);
export const VehicleStoragePage = universal(
	import(
		/* webpackChunkName: "VehicleStoragePage" */ './VehicleStoragePage/VehicleStoragePage'
	),
	options,
);
export const StorageNearMePage = universal(
	import(
		/* webpackChunkName: "StorageNearMePage" */ './StorageNearMePage/StorageNearMePage'
	),
	options,
);
export const ClimateControlledPage = universal(
	import(
		/* webpackChunkName: "ClimateControlledPage" */ './ClimateControlledPage/ClimateControlledPage'
	),
	options,
);
export const UnsubscribePage = universal(
	import(
		/* webpackChunkName: "UnsubscribePage" */ './UnsubscribePage/UnsubscribePage'
	),
	options,
);
export const ContactPage = universal(
	import(/* webpackChunkName: "ContactPage" */ './ContactPage/ContactPage'),
	options,
);
