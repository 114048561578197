import { schema } from 'normalizr';

import facilitySchema from './facility';

const bookingSchema = new schema.Entity(
	'bookings',
	{ facility: facilitySchema },
	{ idAttribute: 'confirmationCode' },
);

export default bookingSchema;
