import { isEqual } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import { Route } from 'react-router-dom';
import { routeLoaded, routeUnloaded } from 'store/modules/app';
import {
	scrollTo,
	scrollToElement,
} from '@sparefoot/react-core/utils/scrolling';
import parseSearchLink from '../utils/search/parseSearchLink';

const routeConnect = connect(
	undefined,
	// bind the route actions from the static route definition to the store
	(dispatch, props) => ({
		actions: bindActionCreators(
			{
				...props.actions,
				routeLoaded,
				routeUnloaded,
			},
			dispatch,
		),
	}),
);

export const routeLifecyle = lifecycle({
	componentDidUpdate(prevProps) {
		const { computedMatch, fetchData, location } = this.props;
		if (
			!isEqual(computedMatch.params, prevProps.computedMatch.params) ||
			(prevProps.location.search && !location.search)
		) {
			scrollTo(0, 'auto');
			fetchData();
		}
	},
	componentDidMount() {
		const { actions, loadData, location, ready } = this.props;
		if (ready && loadData) {
			this.props.fetchData();
		}
		actions.routeLoaded(location);
		if (location.hash && ready) {
			scrollToElement(location.hash, { behavior: 'auto' });
		} else if (ready) {
			scrollTo(0, 'auto');
		}
	},
	componentWillUnmount() {
		const { actions, location } = this.props;
		actions.routeUnloaded(location);
	},
	shouldComponentUpdate() {
		return this.props.ready;
	},
});

export const enhance = compose(
	routeConnect,
	withHandlers({
		fetchData: (props) => () =>
			props.loadData({
				match: props.computedMatch,
				locationKey: props.location.key,
				queryParams: parseSearchLink(props.location.search),
				...props,
			}),
	}),
	routeLifecyle,
);

export default enhance(Route);
