import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import BodyClassName from 'react-body-classname';
import classnames from 'classnames';

import { Spinner } from 'components/core/Spinner';

import './LoadingMask.scss';

export default function LoadingMask({ message, modal, relative }) {
	const content = (
		<BodyClassName className={classnames({ 'no-scroll': !relative })}>
			<div className={classnames('loading-mask', { relative })}>
				<Spinner loadingMessage={message} />
			</div>
		</BodyClassName>
	);

	return modal
		? createPortal(content, document.getElementById('js-modal-content'))
		: content;
}

LoadingMask.propTypes = {
	message: PropTypes.string,
	modal: PropTypes.bool,
	relative: PropTypes.bool,
};
