import { Image } from '@sparefoot/react-core';
import { overwriteDisplayName } from '@sparefoot/react-core/segment';
import { startsWith } from 'lodash';
import { on, off, isElementInViewport } from '@sparefoot/react-core/utils/dom';
import {
	branch,
	renderComponent,
	compose,
	withStateHandlers,
	withPropsOnChange,
	lifecycle,
} from 'recompose';

const TRANSPARENT_GIF =
	'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const enhance = compose(
	overwriteDisplayName('Image'),
	branch(({ lazyLoad }) => !lazyLoad, renderComponent(Image)),
	withStateHandlers(
		({ src, lazyLoad }) => ({
			instance: null,
			isVisible: startsWith(src, 'data:image') || !lazyLoad, // Dont lazy load inline images
		}),
		{
			getRef: () => (instance) => ({ instance }),
			handleViewportChange:
				({ instance, isVisible }) =>
				() =>
					isVisible
						? null
						: {
								isVisible: isElementInViewport(instance),
							},
		},
	),
	withPropsOnChange(['src', 'isVisible'], ({ src, isVisible }) => ({
		src: isVisible ? src : TRANSPARENT_GIF,
	})),
	lifecycle({
		componentDidMount() {
			if (this.props.lazyLoad && !this.props.isVisible) {
				on(['scroll', 'resize'], this.props.handleViewportChange);
				this.props.handleViewportChange();
			}
		},
		componentWillUnmount() {
			off(['scroll', 'resize'], this.props.handleViewportChange);
		},
		shouldComponentUpdate(nextProps) {
			return nextProps.isVisible;
		},
		componentDidUpdate(prevProps) {
			if (!prevProps.isVisible && this.props.isVisible) {
				off(['scroll', 'resize'], this.props.handleViewportChange);
			}
		},
	}),
);

export default enhance(Image);
