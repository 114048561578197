import React from 'react';

export default function Facebook() {
	return (
		<svg
			width="9"
			height="17"
			viewBox="0 0 9 17"
			xmlns="http://www.w3.org/2000/svg"
			className="svg facebook"
		>
			<title>Facebook</title>
			<path
				className="shape"
				d="M5.664 16.8V9.14h2.57l.386-2.99H5.664V4.245c0-.865.24-1.455 1.48-1.455h1.58V.116C8.45.082 7.512 0 6.42 0 4.14 0 2.577 1.392 2.577 3.947V6.15H0v2.988h2.577V16.8h3.087z"
				fillRule="evenodd"
			/>
		</svg>
	);
}
