import React from 'react';
import PropTypes from 'prop-types';
import { withStateHandlers } from 'recompose';
import { isEmpty } from 'lodash';
import { Button } from 'components/core/Button';
import { Link } from 'components/core/Link';
import Phone from 'components/svg/Phone';
import { phonePropType } from 'config/propTypes';

import './ClickToCall.scss';

export const enhance = withStateHandlers(
	{ fetched: false },
	{
		onFetchNumber:
			(_, { phone, onFetchNumber }) =>
			() => {
				if (isEmpty(phone)) {
					onFetchNumber();
				}

				return {
					fetched: true,
				};
			},
	},
);

export function ClickToCall({ fetched, onFetchNumber, phone, phoneIcon }) {
	return !phone || !fetched ? (
		<Button
			link
			onClick={onFetchNumber}
			segmentLabel="click-to-call"
			className="click-to-call"
		>
			{phoneIcon && <Phone />} Call to Book
		</Button>
	) : (
		<Link
			secondary
			href={`tel:${phone.number}`}
			segmentLabel="click-to-call"
			className="click-to-call"
		>
			{phoneIcon && <Phone />} {phone.formattedNumber}
		</Link>
	);
}

ClickToCall.propTypes = {
	fetched: PropTypes.bool.isRequired,
	onFetchNumber: PropTypes.func.isRequired,
	phone: phonePropType,
	phoneIcon: PropTypes.bool,
};

ClickToCall.defaultProps = {
	phoneIcon: true,
};

export default enhance(ClickToCall);
