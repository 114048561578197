import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from 'store/modules/reducers';
import middleware from './middleware';

export const INITIALIZE = 'SSDC/INITIALIZE';

export function initialize() {
	return {
		type: INITIALIZE,
	};
}

export default function configureStore(initialState = {}, storeConfig) {
	const store = createStore(
		reducers,
		initialState,
		composeWithDevTools(applyMiddleware(...middleware(storeConfig))),
	);

	return store;
}
