import React from 'react';
import BodyClassName from 'react-body-classname';
import { LoadingMask } from 'components/core/LoadingMask';

import './Loading.scss';

export default function LoadingPage() {
	return (
		<BodyClassName className="no-scroll no-footer">
			<div className="page-loading">
				<LoadingMask />
			</div>
		</BodyClassName>
	);
}
