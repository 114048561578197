import thunk from 'redux-thunk';
import api from './api';
import appReadyMiddleware from './appReady';
import bookingSuccessMiddleware from './bookings';
import experimentsMiddleware from './experiments';

export default (config) => [
	thunk,
	api,
	appReadyMiddleware,
	experimentsMiddleware,
	bookingSuccessMiddleware
];
