import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { clamp } from 'lodash';

import './Column.scss';

export default function Column({
	children,
	className,
	small,
	medium,
	large,
	xlarge,
}) {
	return (
		<div
			className={classnames(
				'page-column',
				className,
				!!small && `sm-${clamp(small, 1, 12)}`,
				!!medium && `md-${clamp(medium, 1, 12)}`,
				!!large && `lg-${clamp(large, 1, 12)}`,
				!!xlarge && `xlg-${clamp(xlarge, 1, 12)}`,
			)}
		>
			{children}
		</div>
	);
}

Column.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	small: PropTypes.number,
	medium: PropTypes.number,
	large: PropTypes.number,
	xlarge: PropTypes.number,
};
