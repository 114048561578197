import { startsWith } from 'lodash';
import { withHandlers } from 'recompose';
import { scrollToElement } from '@sparefoot/react-core/utils/scrolling';

import Button from './Button';

export const enhance = withHandlers({
	onClick:
		({ href, offset }) =>
		(event) => {
			if (startsWith(href, '#')) {
				event.preventDefault();
				scrollToElement(href, { offset });
			}
		},
});

export default enhance(Button);
