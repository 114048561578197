import React, { Fragment } from 'react';

import { withFixedSegmentCategory } from '@sparefoot/react-core/segment';

import states from 'config/states';
import { Button } from 'components/core/Button';
import { Paragraph } from 'components/core/Typography';

import './StatesList.scss';

export const enhance = withFixedSegmentCategory('state list interactions');

export function StatesList() {
	return (
		<Fragment>
			<Paragraph>Storage by State</Paragraph>
			<ul className="states-list">
				{Object.keys(states).map((state) => (
					<li
						key={state}
						className="state"
					>
						<Button
							secondary
							className="state-button"
							segmentLabel={states[state]}
							to={`/self-storage/${states[state].replace(/ /g, '-').toLowerCase()}/`}
						>
							{state}
						</Button>{' '}
					</li>
				))}
			</ul>
		</Fragment>
	);
}

export default enhance(StatesList);
