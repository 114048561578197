/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import Route from 'containers/Route';

import {
	ConfirmationPage,
	UnsubscribePage,
	ErrorPage,
	FacilityPage,
	HomePage,
	SearchPage,
	UnitPage,
	LegalPage,
	LegalReviewPage,
	MarketingLandingPage,
	MoveInConfirmationPage,
	StatePage, // use during the new state pages redesign
	StorageBasicsPage,
	StyleGuide,
	VehicleStoragePage,
	StorageNearMePage,
	ContactPage,
	ClimateControlledPage,
	ContactPageV2,
} from 'pages/Bundles';

import { getBooking, updateMoveInConfirmation } from 'store/modules/bookings';
import { getHomepage, getStateLandingPage } from 'store/modules/pages';
import { getUnit } from 'store/modules/units';
import { getFacility } from 'store/modules/facilities';
import {
	getSearchPage,
	getCityLandingPage,
	getVehicleCityLandingPage,
	getAmenityCityLandingPage,
} from 'store/modules/search';

export const staticRoutes = [
	{
		path: '/',
		actions: { getHomepage },
		component: HomePage,
		loadData: (props) => props.actions.getHomepage(),
	},
	{
		path: '/contact-us',
		component: ContactPageV2,
	},
	{
		path: '/self-storage/:state([0-9a-zA-Z-]+)(/)?',
		component: StatePage,
		actions: { getStateLandingPage },
		loadData: (props) =>
			props.actions.getStateLandingPage(props.match.params.state),
	},
	{
		path: '/self-storage/:state([0-9a-zA-Z-]+)/:city([0-9a-zA-Z-]+)(/)?',
		key: 'search',
		component: SearchPage,
		actions: { getCityLandingPage },
		loadData: (props) =>
			props.actions.getCityLandingPage(
				props.match.params,
				props.queryParams,
				props.locationKey,
			),
	},
	{
		path: '/self-storage/:state([0-9a-zA-Z-]+)/:city([0-9a-zA-Z-]+)/:vehicle(car|boat|rv)-storage(/)?',
		key: 'search',
		component: SearchPage,
		actions: { getVehicleCityLandingPage },
		loadData: (props) =>
			props.actions.getVehicleCityLandingPage(
				props.match.params,
				props.queryParams,
				props.locationKey,
			),
	},
	{
		path: '/self-storage/:state([0-9a-zA-Z-]+)/:city([0-9a-zA-Z-]+)/:amenity(24-hour-access|climate-controlled)(/)?',
		key: 'search',
		component: SearchPage,
		actions: { getAmenityCityLandingPage },
		loadData: (props) =>
			props.actions.getAmenityCityLandingPage(
				props.match.params,
				props.queryParams,
				props.locationKey,
			),
	},
	{
		path: '/search(/)?',
		key: 'search',
		component: SearchPage,
		actions: { getSearchPage },
		loadData: (props) =>
			props.actions.getSearchPage(props.queryParams, props.locationKey),
	},
	{
		path: '/self-storage/:state([0-9a-zA-Z-]+)/:city([0-9a-zA-Z-]+)(/ogf)?/([0-9a-zA-Z-]+)?-:facilityId([0-9]+)(/)?',
		component: FacilityPage,
		actions: { getFacility },
		loadData: ({ actions, queryParams, match: { params } }) =>
			actions.getFacility(
				params.facilityId,
				params.city,
				params.state,
				queryParams,
			),
	},
	{
		path: '/facility/:facilityId([0-9]+)(/)?',
		component: FacilityPage,
		actions: { getFacility },
		loadData: ({ actions, match: { params } }) =>
			actions.getFacility(params.facilityId),
	},
	{
		path: '/self-storage/:state([0-9a-zA-Z-]+)/:city([0-9a-zA-Z-]+)/([0-9a-zA-Z-]+)?-:facilityId([0-9]+)/reserve/:unitId([0-9]+)(/)?',
		component: UnitPage,
		actions: { getUnit },
		loadData: ({
			actions,
			match: {
				params: { facilityId, unitId },
			},
		}) => actions.getUnit(facilityId, unitId),
	},
	{
		path: '/reservation/confirm-:moveInStatus(.*move-in)/:confirmationCode([A-Za-z0-9]+)(/)?',
		component: MoveInConfirmationPage,
		actions: { updateMoveInConfirmation },
		loadData: ({ actions, match: { params } }) =>
			actions.updateMoveInConfirmation(
				params.confirmationCode,
				params.moveInStatus,
			),
	},
	{
		path: '/reservation/confirmation/:confirmationCode([0-9a-zA-Z_-]+)(/)?',
		component: ConfirmationPage,
		actions: { getBooking },
		loadData: (props) =>
			props.actions.getBooking(props.match.params.confirmationCode),
	},
	{
		path: '/vehicle-storage(/)?',
		component: VehicleStoragePage,
	},
	{
		path: '/storage-units-near-me(/)?',
		component: StorageNearMePage,
	},
	{
		path: '/storage-units-with-climate-control(/)?',
		component: ClimateControlledPage,
	},
	{
		path: '/legal/consumer(/)?',
		component: LegalPage,
	},
	{
		path: '/legal/reviews(/)?',
		component: LegalReviewPage,
	},
	{
		path: '/lpg/lf/:landingPage(hp-copy|storage-location)(/)?',
		component: MarketingLandingPage,
	},
	{
		path: '/unsubscribe(/)?',
		component: UnsubscribePage,
	},
	{
		path: '/contact-selfstorage(/)?',
		component: ContactPage,
	},
	{
		path: '/(what-to-expect|whattoexpect)(/)?',
		component: StorageBasicsPage,
	},
	{
		path: '/ux',
		component: StyleGuide,
	},
	{
		component: ErrorPage,
	},
];

export default function Routes({ ready }) {
	return (
		<Switch>
			{staticRoutes.map((route, i) => (
				<Route
					key={route.key || i}
					exact
					strict
					ready={ready}
					{...route}
				/>
			))}
		</Switch>
	);
}

Routes.propTypes = {
	ready: PropTypes.bool,
};
