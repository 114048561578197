import React from 'react';

export default function Twitter() {
	return (
		<svg
			width="18"
			height="15"
			viewBox="0 0 18 15"
			xmlns="http://www.w3.org/2000/svg"
			className="svg twitter"
		>
			<title>Twitter</title>
			<path
				d="M17.725 1.703c-.65.288-1.352.488-2.088.575.752-.45 1.325-1.163 1.6-2.014-.702.417-1.48.72-2.314.883C14.263.443 13.317 0 12.273 0c-2.007 0-3.635 1.628-3.635 3.635 0 .286.033.562.092.828C5.706 4.31 3.03 2.866 1.234.665.92 1.2.74 1.828.74 2.495c0 1.26.645 2.374 1.618 3.022-.595-.016-1.152-.183-1.644-.455v.045c0 1.762 1.255 3.233 2.915 3.57-.304.08-.628.123-.958.123-.232 0-.46-.02-.686-.064.465 1.444 1.805 2.492 3.396 2.526-1.245.973-2.813 1.552-4.517 1.552-.292 0-.578-.017-.865-.05C1.612 13.8 3.52 14.4 5.576 14.4c6.685 0 10.342-5.54 10.342-10.34l-.01-.472c.714-.513 1.33-1.152 1.817-1.883"
				fillRule="evenodd"
			/>
		</svg>
	);
}
