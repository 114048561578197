import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/core/Typography';

import './Spinner.scss';

export default function Spinner({ loadingMessage }) {
	return (
		<div className="ss-spinner">
			<div className="spinner-content">
				<div className="spinner-animation">
					<div>Loading…</div>
				</div>
				<Text className="spinner-message">{loadingMessage}</Text>
			</div>
		</div>
	);
}

Spinner.propTypes = {
	loadingMessage: PropTypes.string,
};

Spinner.defaultProps = {
	loadingMessage: 'Loading…',
};
