import { get } from 'lodash';
import API from '@sparefoot/react-core/symbols/api';
import bookingSchema from 'store/schema/booking';

export const BOOKING_REQUEST = 'SSDC/BOOKINGS/BOOKING_REQUEST';
export const BOOKING_SUCCESS = 'SSDC/BOOKINGS/BOOKING_SUCCESS';
export const BOOKING_FAILURE = 'SSDC/BOOKINGS/BOOKING_FAILURE';
export const CREATE_REQUEST = 'SSDC/BOOKINGS/CREATE_REQUEST';
export const CREATE_SUCCESS = 'SSDC/BOOKINGS/CREATE_SUCCESS';
export const CREATE_FAILURE = 'SSDC/BOOKINGS/CREATE_FAILURE';
export const UPDATE_REQUEST = 'SSDC/BOOKINGS/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'SSDC/BOOKINGS/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'SSDC/BOOKINGS/UPDATE_FAILURE';
export const CANCEL_REQUEST = 'SSDC/BOOKINGS/CANCEL_REQUEST';
export const CANCEL_SUCCESS = 'SSDC/BOOKINGS/CANCEL_SUCCESS';
export const CANCEL_FAILURE = 'SSDC/BOOKINGS/CANCEL_FAILURE';
export const MOVEIN_CONFIRMATION_SUCCESS =
	'SSDC/BOOKINGS/MOVEIN_CONFIRMATION_SUCCESS';
export const MOVEIN_CONFIRMATION_FAILURE =
	'SSDC/BOOKINGS/MOVEIN_CONFIRMATION_FAILURE';
export const SMS_OPT_IN_AND_SEND_CONFIRMATION_REQUEST =
	'SSDC/BOOKINGS/SMS_OPT_IN_AND_SEND_CONFIRMATION_REQUEST';
export const SMS_OPT_IN_AND_SEND_CONFIRMATION_SUCCESS =
	'SSDC/BOOKINGS/SMS_OPT_IN_AND_SEND_CONFIRMATION_SUCCESS';
export const SMS_OPT_IN_AND_SEND_CONFIRMATION_FAILURE =
	'SSDC/BOOKINGS/SMS_OPT_IN_AND_SEND_CONFIRMATION_FAILURE';
export const SMS_OPT_OUT_REQUEST = 'SSDC/BOOKINGS/SMS_OPT_OUT_REQUEST';
export const SMS_OPT_OUT_SUCCESS = 'SSDC/BOOKINGS/SMS_OPT_OUT_SUCCESS';
export const SMS_OPT_OUT_FAILURE = 'SSDC/BOOKINGS/SMS_OPT_OUT_FAILURE';

export const initialState = {
	byId: {},
	loading: false,
	error: null,
	statusCode: null,
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case BOOKING_REQUEST:
		case UPDATE_REQUEST:
		case CANCEL_REQUEST:
		case CREATE_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				statusCode: null,
			};
		case BOOKING_SUCCESS:
		case UPDATE_SUCCESS:
		case CANCEL_SUCCESS:
		case CREATE_SUCCESS: {
			const bookings = get(action, 'payload.entities.bookings');
			if (bookings) {
				return {
					...state,
					loading: false,
					byId: {
						...state.byId,
						...bookings,
					},
				};
			}
			return state;
		}
		case UPDATE_FAILURE:
		case CANCEL_FAILURE:
		case CREATE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.errors,
			};
		case BOOKING_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.errors,
				statusCode: 404,
			};
		case SMS_OPT_OUT_SUCCESS:
			return {
				...state,
				byId: {
					...state.byId,
					[action.meta.confirmationCode]: {
						...state.byId[action.meta.confirmationCode],
						isOptedIn: action.payload.shouldSend,
						optInError: false,
					},
				},
			};
		case SMS_OPT_IN_AND_SEND_CONFIRMATION_SUCCESS:
			return {
				...state,
				byId: {
					...state.byId,
					[action.meta.confirmationCode]: {
						...state.byId[action.meta.confirmationCode],
						isOptedIn: action.payload.shouldSend,
						optInError: false,
					},
				},
			};
		case SMS_OPT_OUT_FAILURE:
		case SMS_OPT_IN_AND_SEND_CONFIRMATION_FAILURE:
			return {
				...state,
				byId: {
					...state.byId,
					[action.meta.confirmationCode]: {
						...state.byId[action.meta.confirmationCode],
						optInError: true,
					},
				},
			};
		default:
			return state;
	}
}

export function getBooking(confirmationCode) {
	return {
		[API]: {
			types: [BOOKING_REQUEST, BOOKING_SUCCESS, BOOKING_FAILURE],
			endpoint: `/api/booking/${confirmationCode}`,
			schema: bookingSchema,
		},
	};
}

export function createBooking(data) {
	return {
		[API]: {
			types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
			method: 'POST',
			endpoint: '/api/booking',
			schema: bookingSchema,
			data,
		},
	};
}

export function updateBooking(confirmationCode, data) {
	return {
		[API]: {
			types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
			method: 'PUT',
			endpoint: `/api/booking/${confirmationCode}`,
			schema: bookingSchema,
			data,
		},
	};
}

export function MoveInConfirmationSuccess(payload) {
	return {
		type: MOVEIN_CONFIRMATION_SUCCESS,
		payload,
	};
}

export function MoveInConfirmationFailure(error) {
	return {
		type: MOVEIN_CONFIRMATION_FAILURE,
		error,
	};
}

export function updateMoveInConfirmation(confirmationCode, moveInStatus) {
	const consumerMoveInStatus = moveInStatus === 'move-in';
	return (dispatch) =>
		dispatch(
			updateBooking(confirmationCode, { consumerMoveInStatus }),
		).then(({ error, payload }) => {
			if (error) {
				dispatch(MoveInConfirmationFailure(error));
			} else {
				dispatch(MoveInConfirmationSuccess(payload));
			}
		});
}

export function cancelBooking(confirmationCode, userId, data) {
	return {
		[API]: {
			types: [CANCEL_REQUEST, CANCEL_SUCCESS, CANCEL_FAILURE],
			method: 'DELETE',
			endpoint: `/api/booking/${confirmationCode}/${userId}`,
			schema: bookingSchema,
			data,
		},
	};
}

export function reservationSmsOptInAndSendConfirmation(
	phone,
	confirmationCode,
	userId,
) {
	return {
		[API]: {
			types: [
				SMS_OPT_IN_AND_SEND_CONFIRMATION_REQUEST,
				SMS_OPT_IN_AND_SEND_CONFIRMATION_SUCCESS,
				SMS_OPT_IN_AND_SEND_CONFIRMATION_FAILURE,
			],
			method: 'POST',
			endpoint: `/api/sms/optIn/sendConfirmation/${confirmationCode}`,
			data: {
				campaignName: 'reservation',
				phone,
				userId,
				confirmationCode,
			},
			meta: {
				confirmationCode,
			},
		},
	};
}

export function reservationSmsOptOut(phone, confirmationCode, userId) {
	return {
		[API]: {
			types: [
				SMS_OPT_OUT_REQUEST,
				SMS_OPT_OUT_SUCCESS,
				SMS_OPT_OUT_FAILURE,
			],
			method: 'POST',
			endpoint: '/api/sms/optOut',
			data: {
				campaignName: 'reservation',
				phone,
				userId,
				confirmationCode,
			},
			meta: {
				confirmationCode,
			},
		},
	};
}
