import API from '@sparefoot/react-core/symbols/api';
import { normalize } from 'normalizr';
import RequestError from 'utils/error/request';
import { omit, omitBy, isUndefined } from 'lodash';
import axios from 'axios';

export function callApi(
	url,
	options = {
		method: 'GET',
		headers: {},
		query: {},
		data: {},
	},
	schema,
) {
	return axios({
		url,
		params: options.query,
		data: options.data,
		headers: options.headers,
		validateStatus: (status) => status < 400,
		...options,
	})
		.then(({ data }) =>
			schema
				? { ...normalize(data, schema), meta: data.meta || {} }
				: data,
		)
		.catch((error) => {
			throw new RequestError(error);
		});
}

export default (store) => (next) => (action) => {
	const callAPI = action[API];

	// Skip if not an API Call
	if (typeof callAPI === 'undefined') {
		return next(action);
	}

	const actionWith = (actionData) => omit({ ...action, ...actionData }, API);

	const { app, user } = store.getState();
	const {
		types: [
			// Action Types
			requestType,
			successType,
			failureType,
		],
		endpoint, // API Endpoint
		method, // REST Method
		data, // Post Data
		query, // Query Params
		meta, // Action Meta
		schema, // Entity Schema
	} = callAPI;

	// Fire Request Action
	next(
		actionWith(
			omitBy({ type: requestType, meta, query, data }, isUndefined),
		),
	);

	const ssagHost = app.ssag_host_internal || app.ssag_host;
	const requestURL = `${ssagHost}${endpoint}`;

	// Build Request Options
	const options = {
		headers: {
			token: process.env.SSAG_TOKEN,
			'app-id': app.id,
			'site-id': user.siteId,
			'visit-id': user.visitId,
			'traffic-source-id': user.traffic_source_id,
			cid: user.cid,
			ctid: user.ctid,
			'x-is-bot':
				user.visitId === 'bot' || user.visitId === 'error' ? 1 : 0,
			'x-sparefoot-ip': user.user_ip,
		},
		method: method || 'GET',
		data,
		query,
	};
	return callApi(requestURL, options, schema)
		.then((payload) =>
			next(
				actionWith({
					type: successType,
					meta,
					payload,
				}),
			),
		)
		.catch(({ error, status, errorMessage, response }) =>
			next(
				actionWith({
					type: failureType,
					meta,
					status,
					error:
						errorMessage ||
						(error && error.message) ||
						'Something really bad happened',
					payload: response,
				}),
			),
		);
};
