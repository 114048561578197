import { get } from 'lodash';
import Segment from '@sparefoot/segment-react';
import { CREATE_SUCCESS } from 'store/modules/bookings';

export default () => (next) => (action) => {
	if (action.type === CREATE_SUCCESS) {
		// Triggers the GTM Adwords Conversion Event
		const confirmationCode = action.payload.result;
		const booking = get(
			action,
			`payload.entities.bookings[${confirmationCode}]`,
		);
		Segment.track('CON EVENT', {
			CON: {
				event: {
					name: 'bookingCreated',
				},
				facility: {
					id: booking.facilityId,
				},
				location: booking.facilityLocation,
				transaction: {
					confirmation_code: booking.confirmationCode,
					email: booking.email,
					id: booking.confirmationCode,
					npv: booking.npv,
				},
			},
		});
	}

	return next(action);
};
