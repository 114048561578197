import { connect } from 'react-redux';
import { mergeUrlQueryParams } from '@sparefoot/react-core/utils/url';

import { Image } from 'components/core/Image';

import './TrackingPixel.scss';

export const enhance = connect(({ app }, { src }) => ({
	src: `${app.trackingURL}${mergeUrlQueryParams(src, { a: app.trackingId })}`,
	className: 'tracking-pixel',
	alt: 'tracking pixel',
}));

export default enhance(Image);
