import API from '@sparefoot/react-core/symbols/api';
import { get } from 'lodash';
import mergeEntities from 'utils/store/mergeEntities';
import { buildSearchLink } from 'utils/search';
import { redirectState } from 'utils/store';
import { UNIT_SUCCESS } from './units';
import {
	SEARCH_SUCCESS,
	SEARCH_PAGE_SUCCESS,
	SEARCH_LANDING_PAGE_SUCCESS,
} from './search';
import { GET_HIGHEST_REQUEST, GET_HIGHEST_SUCCESS } from './reviews';
import { BOOKING_SUCCESS } from './bookings';
import FacilitySchema from '../schema/facility';

// ------------------------------------
// Constants
// ------------------------------------
export const FACILITY_REQUEST = 'SSDC/FACILITIES/FACILITY_REQUEST';
export const FACILITY_SUCCESS = 'SSDC/FACILITIES/FACILITY_SUCCESS';
export const FACILITY_FAILURE = 'SSDC/FACILITIES/FACILITY_FAILURE';
export const FACILITY_PHONE_REQUEST = 'SSDC/FACILITIES/FACILITY_PHONE_REQUEST';
export const FACILITY_PHONE_SUCCESS = 'SSDC/FACILITIES/FACILITY_PHONE_SUCCESS';
export const FACILITY_PHONE_FAILURE = 'SSDC/FACILITIES/FACILITY_PHONE_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
	loading: false,
	error: null,
	statusCode: null,
	phone: {},
	byId: {},
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case FACILITY_REQUEST:
			return {
				...state,
				loading: true,
				statusCode: null,
			};
		case FACILITY_SUCCESS: {
			const facilities = get(action, 'payload.entities.facilities', {});
			const facilityId = get(action, 'payload.result');
			const facilityUrl = get(facilities, `${facilityId}.url`);
			const { city, state: facilityState } = get(action, 'meta', {});
			if (city && facilityState) {
				const cityState = `${facilityState}/${city}`;
				if (facilityUrl && !facilityUrl.includes(cityState)) {
					return redirectState(state, facilityUrl);
				}
			}

			return {
				...state,
				loading: false,
				byId: mergeEntities(state.byId, facilities),
			};
		}
		case FACILITY_FAILURE: {
			const redirect = get(
				action,
				'payload.context.canonicalRedirectUrl',
			);
			const reason = get(action, 'payload.data.error.reason');
			const {
				city,
				state: facilityState,
				queryParams = {},
			} = get(action, 'meta', {});

			if (redirect) {
				return redirectState(state, redirect);
			}
			if (city && facilityState && reason === 'inactive') {
				const searchURL = buildSearchLink(
					{
						...queryParams,
						location: `${city}, ${facilityState}`,
						facilityNotFound: true,
					},
					true,
				);

				return redirectState(state, searchURL, 410);
			}
			if (city && facilityState && reason !== 'notfound') {
				const searchURL = buildSearchLink(
					{
						...queryParams,
						location: `${city}, ${facilityState}`,
						facilityNotFound: true,
					},
					true,
				);

				return redirectState(state, searchURL, 302);
			}
			return {
				...state,
				error: action.error,
				loading: false,
				statusCode: 404,
			};
		}
		case FACILITY_PHONE_SUCCESS: {
			const { facilityId } = action.meta;
			return {
				...state,
				byId: {
					...state.byId,
					[facilityId]: {
						...state.byId[facilityId],
						phone: action.payload.phone,
					},
				},
			};
		}
		case GET_HIGHEST_REQUEST: {
			const { facilityId } = action.meta;

			return {
				...state,
				byId: {
					...state.byId,
					[facilityId]: {
						...state.byId[facilityId],
						fetchedHighestReview: true,
					},
				},
			};
		}
		case GET_HIGHEST_SUCCESS: {
			const {
				meta: { facilityId },
				payload,
			} = action;

			return {
				...state,
				byId: {
					...state.byId,
					[facilityId]: {
						...state.byId[facilityId],
						highestReview: payload.result,
					},
				},
			};
		}
		case UNIT_SUCCESS:
		case SEARCH_SUCCESS:
		case SEARCH_PAGE_SUCCESS:
		case SEARCH_LANDING_PAGE_SUCCESS:
		case BOOKING_SUCCESS: {
			const facilities = get(action, 'payload.entities.facilities');
			if (facilities) {
				return {
					...state,
					byId: mergeEntities(state.byId, facilities),
				};
			}
			return state;
		}
		default:
			return state;
	}
}

// ------------------------------------
// Actions
// ------------------------------------
export function getFacility(facilityId, city, state, queryParams) {
	return {
		[API]: {
			types: [FACILITY_REQUEST, FACILITY_SUCCESS, FACILITY_FAILURE],
			meta: {
				city,
				state,
				queryParams,
			},
			endpoint: `/api/facility/${facilityId}`,
			schema: FacilitySchema,
		},
	};
}

export function getFacilityPhoneNumber(facilityId) {
	return {
		[API]: {
			types: [
				FACILITY_PHONE_REQUEST,
				FACILITY_PHONE_SUCCESS,
				FACILITY_PHONE_FAILURE,
			],
			endpoint: `/api/phone/facility/${facilityId}`,
			meta: { facilityId },
		},
	};
}

export function getFacilityCardPhoneNumber(city, state, facilityId) {
	return {
		[API]: {
			types: [
				FACILITY_PHONE_REQUEST,
				FACILITY_PHONE_SUCCESS,
				FACILITY_PHONE_FAILURE,
			],
			endpoint: `/api/phone/local/${city}/${state}`,
			meta: { facilityId },
		},
	};
}
