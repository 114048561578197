import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { withRouter } from 'react-router-dom';
import withCookies from 'react-cookie/lib/withCookies';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { mapDispatchToActions } from '@sparefoot/react-core/utils';

import { phonePropType } from 'config/propTypes';

import applicationSelector from 'store/selectors/application';
import { appReady, componentRenderError } from 'store/modules/app';
import { getTollfreePhoneNumber } from 'store/modules/phone';

import { Header } from 'components/brand/Header';
import { Footer } from 'components/brand/Footer';
import { ExperimentModal } from 'components/experiment/ExperimentModal';
import { ErrorPage } from 'pages/Bundles';
import { TrackingPixel } from 'components/core/TrackingPixel';

import { Head } from 'components/core/Head';

export const enhance = compose(
	withRouter,
	withCookies,
	connect(
		applicationSelector,
		{ appReady, componentRenderError, getTollfreePhoneNumber },
		mapDispatchToActions,
	),
	lifecycle({
		componentDidMount() {
			this.props.actions.appReady();
		},
		componentDidCatch(error, info) {
			this.props.actions.componentRenderError(error, info.componentStack);
		},
	}),
);

export function ApplicationWrapper({
	errorMessage,
	phone,
	sftest,
	statusCode,
	actions,
	ready,
}) {
	return (
		<div>
			{/* Render the default Head tags */}
			<Head />
			<Header
				phone={phone}
				getTollfreePhoneNumber={actions.getTollfreePhoneNumber}
			/>
			<main>
				{statusCode === 200 && <Routes ready={ready} />}
				{statusCode !== 200 && (
					<ErrorPage
						statusCode={statusCode}
						message={errorMessage}
					/>
				)}
			</main>
			{sftest && <ExperimentModal />}
			<Footer
				phone={phone}
				getTollfreePhoneNumber={actions.getTollfreePhoneNumber}
			/>
			<TrackingPixel src="/imageVerifyVisit" />
		</div>
	);
}

ApplicationWrapper.propTypes = {
	sftest: PropTypes.bool.isRequired,
	statusCode: PropTypes.number.isRequired,
	phone: phonePropType,
	errorMessage: PropTypes.string,
	actions: PropTypes.shape({
		getTollfreePhoneNumber: PropTypes.func.isRequired,
	}).isRequired,
	ready: PropTypes.bool,
};

export default enhance(ApplicationWrapper);
