import { get, isEmpty } from 'lodash';
import { setExperimentsBucket } from '@sparefoot/react-core/modules/experiments';

export default (store) => (next) => (action) => {
	const { user } = store.getState();
	const experiments = get(action, 'payload.experiments');
	if (!isEmpty(experiments) && user.visitId !== 'SELENIUM') {
		store.dispatch(setExperimentsBucket(experiments));
	}

	return next(action);
};
