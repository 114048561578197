import { isArray, reduce, mergeWith } from 'lodash';

function arrayHandler(objValue, srcValue) {
	if (isArray(objValue)) {
		return srcValue;
	}

	return undefined;
}

export default function mergeEntities(bag = {}, entities) {
	return reduce(
		Object.keys(entities),
		(newBag, id) => ({
			...newBag,
			[id]: mergeWith(newBag[id], entities[id], arrayHandler),
		}),
		bag,
	);
}
