import { logBucketing } from '@sparefoot/react-core/modules/experiments';
import { READY } from 'store/modules/app';

export default (store) => (next) => (action) => {
	if (action.type === READY) {
		store.dispatch(logBucketing());
	}

	return next(action);
};
