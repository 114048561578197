import { Checkbox } from '@sparefoot/react-core';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { on, off } from '@sparefoot/react-core/utils/dom';
import { Link } from 'components/core/Link';
import Dropdown from 'components/svg/Dropdown';
import { compose } from 'recompose';
import { withFixedSegmentCategory } from '@sparefoot/react-core/segment';
import { withShowHide } from '@sparefoot/react-core/decorators';

export const enhance = compose(
	withFixedSegmentCategory('b2b interactions'),
	withShowHide('dropdown'),
);

export class Menu extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.topAccordionId = 'menu-toggle-button';
		this.secondaryAccordionId = 'storage-types-checkbox';
		this.ownersAccordionId = 'owners-checkbox';
	}

	componentDidMount() {
		on('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		off('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event) {
		// if event.target is outside of the dropdown, close the dropdown
		const { className } = event.target;

		// Closes both levels on click outside mobile
		const firstDropdown = document.getElementById(this.topAccordionId);
		const secondaryMenu = document.getElementById(
			this.secondaryAccordionId,
		);
		const ownersDropdown = document.getElementById(this.ownersAccordionId);

		// Prevents links in menu or dropdown icons from counting as a "click outside" / short circuit for svg icon object
		const isRelevantElement =
			!!className &&
			typeof className === 'string' &&
			(className?.includes('menu-link') ||
				className?.includes('icon-bars'));

		// Accounts for SVG icons returning as an object
		if (!isRelevantElement && typeof className !== 'object') {
			firstDropdown.checked = false;
			secondaryMenu.checked = false;
			ownersDropdown.checked = false;

			this.props.onSetDropdown(false);
			this.props.onSetOwners(false);
		}
	}

	render() {
		const {
			dropdownShowing,
			onToggleDropdown,
			ownersShowing,
			onToggleOwners,
		} = this.props;

		return (
			<div className="nav-menu">
				<ul className="menu-list">
					<li className="menu-item">
						<Link
							className="menu-link"
							href="/storage-units-near-me"
							rel="noopener noreferrer"
							segmentLabel="find storage"
						>
							Find Storage
						</Link>
					</li>

					<li className="menu-item">
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label
							className={classnames(
								'menu-link dropdown-toggle ss-link',
							)}
							htmlFor="storage-types-checkbox"
						>
							<Checkbox
								onChange={onToggleDropdown}
								key="Storage Types Dropdown"
								id="storage-types-checkbox"
								name="storage-types-checkbox"
								segmentLabel="storage types dropdown"
								className="visually-hidden"
								checked={dropdownShowing}
							/>
							Storage Types <Dropdown />
						</label>
						<div>
							<ul
								className={classnames('dropdown-menu', {
									showing: dropdownShowing,
								})}
							>
								<li className="menu-item">
									<Link
										className="menu-link"
										href="/vehicle-storage"
										rel="noopener noreferrer"
										segmentLabel="storage types dropdown-header-vehicle storage"
									>
										Vehicle Storage
									</Link>
								</li>
								<li className="menu-item">
									<Link
										className="menu-link"
										href="/storage-units-with-climate-control"
										rel="noopener noreferrer"
										segmentLabel="storage types dropdown-header-climate controlled storage"
									>
										Climate Controlled Storage
									</Link>
								</li>
							</ul>
						</div>
					</li>
					<li className="menu-item">
						<Link
							className="menu-link"
							href="/what-to-expect"
							rel="noopener noreferrer"
							segmentLabel="facility types Links-Size Guide"
						>
							Size Guide
						</Link>
					</li>
					<li className="menu-item">
						<Link
							className="menu-link"
							href="https://moving.selfstorage.com/"
							rel="noopener noreferrer"
							segmentLabel="moving blog"
						>
							Blog
						</Link>
					</li>
					<li className="menu-item hide-on-desktop">
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label
							className={classnames(
								'menu-link dropdown-toggle owners-dropdown-menu ss-link',
							)}
							htmlFor="owners-checkbox"
						>
							<Checkbox
								onChange={onToggleOwners}
								key="Owners Dropdown"
								id="owners-checkbox"
								name="owners-checkbox"
								segmentLabel="Owners dropdown"
								className="visually-hidden"
								checked={ownersShowing}
							/>
							Owners <Dropdown />
						</label>
						<div>
							<ul
								className={classnames('dropdown-menu', {
									showing: ownersShowing,
								})}
							>
								<li className="menu-item">
									<Link
										className="menu-link"
										href="https://www.storable.com/products/marketplace/"
										rel="noopener noreferrer"
										segmentLabel="add your facility"
									>
										Add Your Facility
									</Link>
								</li>
								<li className="menu-item">
									<Link
										className="menu-link"
										href="https://argus-selfstorage.com/"
										rel="noopener noreferrer"
										segmentLabel="owners dropdown-header-buy & sell facilities"
									>
										Buy & Sell Facilities
									</Link>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

Menu.propTypes = {
	onToggleDropdown: PropTypes.func.isRequired,
	onToggleOwners: PropTypes.func.isRequired,
	dropdownShowing: PropTypes.bool,
	ownersShowing: PropTypes.bool,
	onSetDropdown: PropTypes.func.isRequired,
	onSetOwners: PropTypes.func.isRequired,
};

export default enhance(Menu);
