import smoothscroll from 'smoothscroll-polyfill';
import Segment from '@sparefoot/segment-react';
import { initialize } from 'store/create';
import { initializeGoogle } from 'utils/search/autocomplete';
import { saveGAClientId } from 'store/modules/user';
import Cookies from 'universal-cookie';

// Initializes the application state
export default ({ dispatch, getState }) => {
	const { app, user } = getState();

	const segment = Segment.initialize(app.segmentWriteKey, {
		siteId: 27,
		visitId: user.visitId,
		environment: app.environment,
		siteType: 'desktop',
		host: app.baseUrl.replace(/^https?:\/\//i, ''),
		debug: app.environment === 'development',
	});

	Segment.identify(user.visitId);

	// Save the GA Client ID if not already set
	segment.analytics.ready(() => {
		window.ga((tracker) => {
			const clientId = tracker.get('clientId');
			if (clientId && clientId !== user.gcid) {
				dispatch(saveGAClientId(clientId));
				const cookies = new Cookies();
				cookies.set('gcid', clientId, { path: '/' });
			}
		});
	});

	initializeGoogle({
		key: app.googleMapsKey,
	});

	smoothscroll.polyfill();

	dispatch(initialize());
};
