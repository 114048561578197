export default (params, prefix) => {
	let urlString = '';

	// Alphabetizes the keys to keep url in alphabetical order
	const sortedParams = Object.keys(params)
		.sort()
		.reduce(
			(acc, key) => ({
				...acc,
				[key]: params[key],
			}),
			{},
		);

	// Loops through sorted params object to build url
	Object.keys(sortedParams).forEach((key) => {
		const value = sortedParams[key];

		if (Array.isArray(value) && value?.length) {
			let arrayQueryString = '';

			// Params with the same name like amenities generate an array in the params object
			// creates multiple instances of the key with each amenity afterwards to search properly
			value.forEach((amenity) => {
				// Only appends amenity if it is a valid string, not ''
				if (amenity.length) {
					if (arrayQueryString !== '') {
						arrayQueryString += '&';
					}
					arrayQueryString += `${key}=${encodeURIComponent(amenity)}`;
				}
			});

			// Handles instances of ? or & when appending query params
			urlString =
				urlString === ''
					? arrayQueryString
					: `${urlString}&${arrayQueryString}`;

			// Prevents appending null/undefined values
		} else if (value) {
			if (urlString !== '') {
				urlString += '&';
			}
			urlString += `${key}=${encodeURIComponent(value)}`;
		}
	});

	return `${prefix ? '/search' : ''}?${urlString}`;
};
