import { combineReducers } from 'redux';

import experiments from '@sparefoot/react-core/modules/experiments';
import { reducer as form } from 'redux-form';
import app from './app';
import bookings from './bookings';
import facilities from './facilities';
import pages from './pages';
import phone from './phone';
import reviews from './reviews';
import search from './search';
import units from './units';
import user from './user';

export default combineReducers({
	app,
	bookings,
	experiments,
	facilities,
	form,
	pages,
	phone,
	reviews,
	search,
	units,
	user,
});
