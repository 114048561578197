import { get, isEmpty } from 'lodash';
import API from '@sparefoot/react-core/symbols/api';
import { FACILITY_SUCCESS } from './facilities';
import {
	SEARCH_SUCCESS,
	SEARCH_PAGE_SUCCESS,
	SEARCH_LANDING_PAGE_SUCCESS,
} from './search';
import reviewSchema from '../schema/review';

// ------------------------------------
// Constants
// ------------------------------------
export const CREATE_REQUEST = 'SSDC/REVIEWS/CREATE_REQUEST';
export const CREATE_SUCCESS = 'SSDC/REVIEWS/CREATE_SUCCESS';
export const CREATE_FAILURE = 'SSDC/REVIEWS/CREATE_FAILURE';
export const GET_HIGHEST_REQUEST = 'SSDC/REVIEWS/GET_HIGHEST_REQUEST';
export const GET_HIGHEST_SUCCESS = 'SSDC/REVIEWS/GET_HIGHEST_SUCCESS';
export const GET_HIGHEST_FAILURE = 'SSDC/REVIEWS/GET_HIGHEST_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
	error: null,
	byId: {},
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case FACILITY_SUCCESS:
		case SEARCH_SUCCESS:
		case SEARCH_PAGE_SUCCESS:
		case SEARCH_LANDING_PAGE_SUCCESS:
		case GET_HIGHEST_SUCCESS: {
			const reviews = get(action, 'payload.entities.reviews');
			if (!isEmpty(reviews)) {
				return {
					...state,
					byId: {
						...state.byId,
						...reviews,
					},
				};
			}
			return state;
		}
		default:
			return state;
	}
}

// ------------------------------------
// Actions
// ------------------------------------

export function onCreateReview(data) {
	return {
		[API]: {
			types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
			endpoint: `/api/facility/${data.facilityId}/review`,
			method: 'POST',
			data,
		},
	};
}

function getHigestReviewByFacilityId(facilityId) {
	return {
		[API]: {
			types: [
				GET_HIGHEST_REQUEST,
				GET_HIGHEST_SUCCESS,
				GET_HIGHEST_FAILURE,
			],
			endpoint: `/api/facility/${facilityId}/highest_review`,
			meta: {
				facilityId,
			},
			schema: reviewSchema,
		},
	};
}

export function getHigestReview(facilityId) {
	// check if the facility has already attemped a fetch first
	return (dispatch, getState) => {
		const {
			facilities: { byId },
		} = getState();
		const facility = byId[facilityId];
		if (!get(facility, 'fetchedHighestReview')) {
			return dispatch(getHigestReviewByFacilityId(facilityId));
		}
		return Promise.resolve();
	};
}
