import API from '@sparefoot/react-core/symbols/api';
import { get } from 'lodash';
import { PageTypes } from '@sparefoot/segment-react';
import searchSchema from 'store/schema/search';
import { ROUTE_LOADED, ROUTE_UNLOADED } from 'store/modules/app';
import { redirectState } from 'utils/store';
import { VIP_ACCOUNTS } from '../../config/accounts';

export const SEARCH_REQUEST = 'SSDC/SEARCH/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SSDC/SEARCH/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SSDC/SEARCH/SEARCH_FAILURE';
export const SEARCH_PAGE_REQUEST = 'SSDC/SEARCH/SEARCH_PAGE_REQUEST';
export const SEARCH_PAGE_SUCCESS = 'SSDC/SEARCH/SEARCH_PAGE_SUCCESS';
export const SEARCH_PAGE_FAILURE = 'SSDC/SEARCH/SEARCH_PAGE_FAILURE';
export const SEARCH_LANDING_PAGE_REQUEST =
	'SSDC/SEARCH/SEARCH_LANDING_PAGE_REQUEST';
export const SEARCH_LANDING_PAGE_SUCCESS =
	'SSDC/SEARCH/SEARCH_LANDING_PAGE_SUCCESS';
export const SEARCH_LANDING_PAGE_FAILURE =
	'SSDC/SEARCH/SEARCH_LANDING_PAGE_FAILURE';
export const SEARCH_FILTERS_UPDATE = 'SSDC/SEARCH/SEARCH_FILTERS_UPDATE';
export const SEARCH_FILTERS_VISIBLE_UPDATE =
	'SSDC/SEARCH/SEARCH_FILTERS_VISIBLE_UPDATE';
export const SEARCH_NEARBY_FACILITIES_REQUEST =
	'SSDC/SEARCH/SEARCH_NEARBY_FACILITIES_REQUEST';
export const SEARCH_NEARBY_FACILITIES_SUCCESS =
	'SSDC/SEARCH/SEARCH_NEARBY_FACILITIES_SUCCESS';
export const SEARCH_NEARBY_FACILITIES_FAILURE =
	'SSDC/SEARCH/SEARCH_NEARBY_FACILITIES_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
	loading: false,
	locationKey: null,
	error: null,
	statusCode: null,
	facetCounts: {},
	facilities: [],
	nearbyFacilities: [],
	filtersShowing: false,
	cityUrl: null,
	stateUrl: null,
	query: {},
	content: {},
	meta: {},
	pageType: null,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case ROUTE_LOADED:
			return {
				...state,
				locationKey: state.locationKey || action.payload.location.key,
			};
		// Workaround: Unset the pagetype when the search route unloads to prevent duplicate page views
		case ROUTE_UNLOADED:
			return {
				...state,
				pageType: null,
			};
		case SEARCH_PAGE_REQUEST:
		case SEARCH_LANDING_PAGE_REQUEST:
		case SEARCH_REQUEST: {
			const locationKey = get(action, 'meta.locationKey');
			return {
				...state,
				locationKey,
				loading: locationKey !== state.locationKey,
				pageType: null,
				nearbyFacilities: [],
			};
		}
		case SEARCH_PAGE_SUCCESS:
		case SEARCH_LANDING_PAGE_SUCCESS:
		case SEARCH_SUCCESS: {
			const { entities, result } = action.payload;
			const search = entities.search[result];
			const alternateCity = get(
				search,
				'content.lowPerformingAlt.alternateCity',
			);
			const alternateState = get(
				search,
				'content.lowPerformingAlt.alternateState',
			);
			if (alternateCity && alternateState) {
				return redirectState(
					state,
					`/self-storage/${alternateState}/${alternateCity}/`,
				);
			}
			return {
				...state,
				searchId: search.searchId,
				searchType: search.searchType,
				facetCounts: search.facetCounts,
				facilities: search.listings,
				filtersShowing: false,
				content:
					action.type === SEARCH_SUCCESS
						? state.content
						: search.content,
				cityUrl: search.cityUrl,
				stateUrl: search.stateUrl,
				headline: search.headline,
				lastInOriginalSearch: search.lastInOriginalSearch,
				location: search.location,
				locationHash: search.locationHash,
				meta: search.meta,
				query: search.query,
				page: search.page,
				staticMapUrl: search.staticMapUrl,
				totalPages: search.totalPages,
				totalListings: search.totalListings,
				listingsPerPage: search.listingsPerPage,
				pageType: search.query.pageType || PageTypes.SEARCH,
				error: null,
				loading: false,
				vipAccounts: VIP_ACCOUNTS,
			};
		}
		case SEARCH_PAGE_FAILURE:
		case SEARCH_LANDING_PAGE_FAILURE:
		case SEARCH_FAILURE:
			return {
				...state,
				error: get(action, 'payload.error', action.error),
				// Only passes through 410 and 404 error codes, ignores 500s. This will render the ErrorPage with a
				// 410 response for the Low Performing Cities Geo Footprint SEO Optimization (SF-7032)
				statusCode:
					action.status === 410 || action.status === 404
						? action.status
						: null,
				loading: false,
			};
		case SEARCH_FILTERS_UPDATE:
			return {
				...state,
				query: action.payload.query,
			};
		case SEARCH_FILTERS_VISIBLE_UPDATE:
			return {
				...state,
				filtersShowing: !!action.showing,
			};
		case SEARCH_NEARBY_FACILITIES_SUCCESS:
			return {
				...state,
				nearbyFacilities: action.payload.listings,
			};
		case SEARCH_NEARBY_FACILITIES_FAILURE:
			return {
				...state,
				error: get(action, 'payload.error', action.error),
				statusCode: action.status === 410 ? 410 : null,
			};
		default:
			return state;
	}
}

// ------------------------------------
// Actions
// ------------------------------------

// Eagerly updates the seach filter UI
// does not actually apply any filters
export function updateSearchFilters(query) {
	return {
		type: SEARCH_FILTERS_UPDATE,
		payload: {
			query,
		},
	};
}

export function showSearchFilters() {
	return {
		type: SEARCH_FILTERS_VISIBLE_UPDATE,
		showing: true,
	};
}

export function hideSearchFilters() {
	return {
		type: SEARCH_FILTERS_VISIBLE_UPDATE,
		showing: false,
	};
}

export function checkLocationKey(locationKey, cb) {
	return (dispatch, getState) => {
		const { search } = getState();
		if (!locationKey || locationKey !== search.locationKey) {
			return dispatch(cb);
		}
		return null;
	};
}

export function getSearchResults(query, locationKey) {
	return checkLocationKey(locationKey, {
		[API]: {
			types: [SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE],
			endpoint: '/api/search',
			meta: {
				locationKey,
			},
			query,
			schema: searchSchema,
		},
	});
}

export function getSearchPage(query, locationKey) {
	return checkLocationKey(locationKey, {
		[API]: {
			types: [
				SEARCH_PAGE_REQUEST,
				SEARCH_PAGE_SUCCESS,
				SEARCH_PAGE_FAILURE,
			],
			endpoint: '/api/search',
			meta: {
				locationKey,
			},
			query,
			schema: searchSchema,
		},
	});
}

export function getSearchLandingPage(query, locationKey) {
	return checkLocationKey(locationKey, {
		[API]: {
			types: [
				SEARCH_LANDING_PAGE_REQUEST,
				SEARCH_LANDING_PAGE_SUCCESS,
				SEARCH_LANDING_PAGE_FAILURE,
			],
			endpoint: '/api/search/landing',
			query,
			meta: {
				locationKey,
			},
			schema: searchSchema,
		},
	});
}

export function getCityLandingPage(params, query, locationKey) {
	return getSearchLandingPage(
		{
			...query,
			city: params.city,
			state: params.state,
			location: `${params.city}, ${params.state}`,
			pageType: PageTypes.CITY_LANDING,
		},
		locationKey,
	);
}

export function getVehicleCityLandingPage(params, query, locationKey) {
	return getSearchLandingPage(
		{
			...query,
			city: params.city,
			state: params.state,
			location: `${params.city}, ${params.state}`,
			type: 'vehicle',
			vehicle: params.vehicle,
			pageType: PageTypes.VEHICLE_LANDING,
		},
		locationKey,
	);
}

export function getAmenityCityLandingPage(params, query, locationKey) {
	return getSearchLandingPage(
		{
			...query,
			city: params.city,
			state: params.state,
			location: `${params.city}, ${params.state}`,
			amenity: params.amenity,
			pageType: PageTypes.AMENITY_LANDING,
			subPageType: params.amenity,
		},
		locationKey,
	);
}

// Although we show X facilities, we pull X+1 because one of the results may be the facility itself
export function getNearbyFacilities(
	location,
	accountId,
	listingsPerPage = 10,
	distance = 10,
	sort = 'distance',
) {
	return {
		[API]: {
			types: [
				SEARCH_NEARBY_FACILITIES_REQUEST,
				SEARCH_NEARBY_FACILITIES_SUCCESS,
				SEARCH_NEARBY_FACILITIES_FAILURE,
			],
			endpoint: '/api/search/slim',
			query: {
				location,
				accountId,
				listingsPerPage,
				distance,
				sort,
			},
		},
	};
}
