import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BodyClassName from 'react-body-classname';
import { compose } from 'recompose';
import {
	overwriteDisplayName,
	withSegmentContext,
	withSegmentPageViewTracking,
} from '@sparefoot/react-core/segment';
import { withCoreWebVitalMetrics } from '@sparefoot/react-core/decorators';

import { metaPropTypes } from 'config/propTypes';
import { TrackingPixel } from 'components/core/TrackingPixel';
import { Head } from 'components/core/Head';

import './Page.scss';

export const enhance = compose(
	overwriteDisplayName('Page'),
	withSegmentContext,
	withSegmentPageViewTracking(),
	withCoreWebVitalMetrics,
);

export function Page({
	children,
	className,
	landingPage,
	marketingPage,
	trackingPixel,
	title,
	description,
	canonical,
	noIndex,
	noFollow,
	showVariation,
}) {
	return (
		<BodyClassName
			className={classnames({
				'landing-page': landingPage && !showVariation,
				'marketing-page': marketingPage,
				'marketing-page-variation': marketingPage && showVariation, // sf_12127_ssdc_paid_landing_refresh
			})}
		>
			<Fragment>
				<Head
					title={title}
					description={description}
					canonical={canonical}
					noIndex={noIndex}
					noFollow={noFollow}
				/>
				<div className={classnames('page', className)}>{children}</div>
				{!!trackingPixel && <TrackingPixel src={trackingPixel} />}
			</Fragment>
		</BodyClassName>
	);
}

Page.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	landingPage: PropTypes.bool,
	marketingPage: PropTypes.bool,
	trackingPixel: PropTypes.string.isRequired,
	...metaPropTypes,
	showVariation: PropTypes.bool,
};

export default enhance(Page);
